import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Photoresists`}</h4>
    <p>{`Photoresists are chemicals deposited on a wafer. When exposed to patterned light that has passed through a photomask, they selectively dissolve to form the circuit pattern. Etching is then performed in places where the photoresist has dissolved to transfer the circuit pattern permanently onto the wafer. Photoresists are specific to particular photolithography processes. Japanese firms are the leading producers.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      